const config = {
  apiDomain: {
    datalogue: 'https://portal-showcase.datalogue.de/api',
    deepideas: 'https://portal-showcase.deepideas.digital/api'
  },
  envSuffix: '-showcase',
  refresh: 5,
  debug: false
};

export default config;
